var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('aad-title',[_vm._v("All Handouts")]),(
      _vm.allSessions == undefined ||
      _vm.allSessions.length == 0 ||
      _vm.allSpeakers == undefined ||
      _vm.allSpeakers.length == 0
    )?_c('aad-loader'):_c('div',[_c('a',{staticClass:"btn btn-primary-solid",attrs:{"href":"#"},on:{"click":_vm.toggleHandoutSessionView}},[(_vm.isHandoutSessionView)?_c('span',[_vm._v("View Speaker Handouts")]):_c('span',[_vm._v("View Session Handouts")])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.isHandoutSessionView)?_c('div',_vm._l((_vm.allSessions),function(session){return _c('div',{key:session.id},[_c('div',{staticClass:"meeting-app-info-block w-100"},[_c('div',{staticClass:"meeting-app-info-header row"},[_c('router-link',{staticClass:"text-link-light-bg",attrs:{"to":{
                  name: 'SessionDetails',
                  params: { id: session.id },
                }},nativeOn:{"click":function($event){return _vm.logSessionClick(session.id, session.SessionCode)},"mouseup":function($event){if('button' in $event && $event.button !== 1){ return null; }return _vm.logSessionClick(session.id, session.SessionCode)}}},[_c('h4',[_vm._v(_vm._s(session.SessionCode)+" - "+_vm._s(session.Name))])])],1),(session.Handouts && session.Handouts.length > 0)?_c('div',{staticClass:"meeting-app-info-body"},[_c('div',{staticClass:"card-deck",attrs:{"id":"card-deck-vert-xs-full"}},_vm._l((session.Handouts),function(handout){return _c('div',{key:handout.HandoutTitle,staticClass:"card img-vert-xs"},[_c('handout-card',{attrs:{"handout":handout,"session":session}})],1)}),0)]):_c('div',{staticClass:"meeting-app-info-body"},[_c('p',[_vm._v("There are no handouts for this session.")])])])])}),0):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(!_vm.isHandoutSessionView)?_c('div',_vm._l((_vm.allSpeakers),function(speaker){return _c('div',{key:speaker.id},[(speaker.Handouts && speaker.Handouts.length > 0)?_c('div',{staticClass:"meeting-app-info-block w-100"},[_c('div',{staticClass:"meeting-app-info-header row"},[_c('router-link',{staticClass:"text-link-light-bg",attrs:{"to":{
                  name: 'SpeakerDetails',
                  params: { id: speaker.id },
                }},nativeOn:{"click":function($event){return _vm.logNavigationToSpeaker(speaker.id, speaker.DisplayName)},"mouseup":function($event){if('button' in $event && $event.button !== 1){ return null; }return _vm.logNavigationToSpeaker(speaker.id, speaker.DisplayName)}}},[_c('h4',[_vm._v(_vm._s(speaker.DisplayName))])])],1),_c('div',{staticClass:"meeting-app-info-body"},[_c('div',{staticClass:"card-deck",attrs:{"id":"card-deck-vert-xs-full"}},_vm._l((speaker.Handouts),function(handout){return _c('div',{key:handout.HandoutTitle,staticClass:"card img-vert-xs"},[_c('handout-card',{attrs:{"handout":handout,"speaker":speaker}})],1)}),0)])]):_vm._e()])}),0):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }